import { Component, Vue, Mixins } from 'vue-property-decorator';
import global from "@/vuex/order/global";
import * as util from '@/util';
import utilMixins from '@/mixins';
import manager from '@/vuex/order/manager';

@Component
export default class mixinCompany extends Mixins(utilMixins) {
  get is_set_global(): boolean {
    return global.is_set;
  }

  get is_valid_global(): boolean {
    return global.is_valid;
  }

  async get_global() {
    await global.fetch();
  }

  get global() {
    if (!this.is_set_global || !this.is_valid_global) this.get_global();
    if (this.is_set_global) 
      return global.data;
  }
  
  get m() {
    return manager;
  }
}