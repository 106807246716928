
import { Component, Vue, Prop, Watch, Mixins } from 'vue-property-decorator';
import * as util from '@/util';
import utilMixins from '@/mixins';
import dialog from "@/vuex/dialog";
import mixinOrder from '@/mixins/mixinOrder';
import CommonProgress from "@/components/common/Progress.vue";
import ChangeplanMain from "@/components/changeplan/Main.vue";

@Component({
  components: {
    CommonProgress,
    ChangeplanMain,
  }
})
export default class Order extends Mixins(mixinOrder) {

}
