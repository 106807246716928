import {
  Mutation,
  MutationAction,
  Action,
  VuexModule,
  getModule,
  Module,
} from "vuex-module-decorators";
import store from "@/store"; // デコレータでstoreを指定するためimportする必要あり
import router from "@/router";
import * as util from "@/util";
import validate from "@/validations/validate";
import dialog from "@/vuex/dialog";
import Vue from "vue";
import global from "./global";
import manager from "./manager";
import header from "@/vuex/header";
import orderOrder from "@/typesold/order";
import node from "@/vuex/node";
import before_login from "@/vuex/before_login";
import session from "@/vuex/session";

@Module({ dynamic: true, store, name: "order_manager", namespaced: true })
class Order_manager extends VuexModule {
  plan: number = 2;
  rental: number = 0;
  order_type: number = 0;

  card_number?: string = null;
  card_yearmonth: string = util.formatDate(new Date(), "yyyy-mm");
  card_cvc?: string = null;
  card_name?: string = null;
  card_back?: boolean = false;

  is_open_cvc_dialog: boolean = false;

  error_msg = {
    "88888888": "メンテナンス中です。",
    "90100100": "通信に失敗しました。",
    "99999999": "その他のシステムエラーが発生しました。",
    "02030105": "METHOD が 'POST' 以外",
    "02030106": "CONTENT-TYPE が 'text/xml' もしくは 'application/xml' 以外",
    "02030107": "CONTENT-LENGTH が存在しないか、0 が指定されている",
    "02030108": "CONTENT-LENGTH が 8192 byte より大きい",
    "02030207": "XML データが未送信",
    "02030208": "XML データが 8192 byte より大きい",
    "02030209": "XML データに構文エラーがある",
    "02080114": "XML の action が空",
    "02080115": "無効な action が指定されている",
    "02130114": "XML に authentication clientip が存在しない",
    "02130117": "clientip のフォーマットが不正",
    "02130110": "不正な clientip が指定された",
    "02130118": "不正な clientip が指定された",
    "02130514": "「カード番号」を入力してください。",
    "02130517": "「カード番号」を正しく入力してください。",
    "02130619": "「カード番号」を正しく入力してください。",
    "02130620": "「カード番号」を正しく入力してください。",
    "02130621": "「カード番号」を正しく入力してください。",
    "02130640": "「カード番号」を正しく入力してください。",
    "02130714": "「有効期限(年)」を入力してください。",
    "02130717": "「有効期限(年)」を正しく入力してください。",
    "02130725": "「有効期限(年)」を正しく入力してください。",
    "02130814": "「有効期限(月)」を入力してください。",
    "02130817": "「有効期限(月)」を正しく入力してください。",
    "02130825": "「有効期限(月)」を正しく入力してください。",
    "02130922": "「有効期限」を正しく入力してください。",
    "02131014": "「セキュリティコード」を正しく入力してください。",
    "02131017": "「セキュリティコード」を正しく入力してください。",
    "02131117": "「カード名義」を正しく入力してください。",
    "02131123": "「カード名義」を正しく入力してください。",
    "02131124": "「カード名義」を正しく入力してください。",
  };

  @Mutation
  public setPlan(val: number) {
    this.plan = val;
  }
  @Mutation
  public setRental(val: number) {
    this.rental = val;
  }
  @Mutation
  public setOrderType(val: number) {
    this.order_type = val;
  }

  @Mutation
  public setCardNumber(val: string) {
    this.card_number = val;
  }
  @Mutation
  public setCardYearmonth(val: string) {
    this.card_yearmonth = val;
  }
  @Mutation
  public setCardCvc(val: string) {
    this.card_cvc = val;
  }
  @Mutation
  public setCardName(val: string) {
    this.card_name = val;
  }
  @Mutation
  public setCardBack(val: boolean) {
    this.card_back = val;
  }

  @Mutation
  public setIsOpenCvcDialog(val: boolean) {
    this.is_open_cvc_dialog = val;
  }

  @Action({ rawError: true })
  public execChangePlan() {
    dialog.openConfirmDialog({
      msg: "以上の内容でプラン変更を行ってよろしいでしょうか？",
      detail: "月額料金が変更になります。",
      cancel_button: "送信しない",
      exec_button: "送信する",
      is_next_dialog: true,
      func: this.execChangePlanProcess,
      args: {},
    });
  }
  @Action({ rawError: true })
  public async execChangePlanProcess({}) {
    dialog.openProgressDialog({ title: "送信中", msg: "この処理には時間がかかることがあります。" });
    await util.post("order/change_plan", { plan: this.plan });
    router.push({ name: "employee", query: util.passquery["employee"] });
    dialog.openAlertDialog({ msg: "プランの変更が完了しました。" });
  }

  @Action({ rawError: true })
  public execOrder() {
    if (this.order_type == 0) {
      router.push({ name: "order_card" });
    } else {
      dialog.openConfirmDialog({
        msg: "以上の内容で本登録の申し込みを行ってよろしいですか？",
        cancel_button: "送信しない",
        exec_button: "送信する",
        is_next_dialog: true,
        func: this.execOrderDebit,
        args: {},
      });
    }
  }
  @Action({ rawError: true })
  public async execOrderDebit({}) {
    dialog.openProgressDialog({ title: "送信中", msg: "この処理には時間がかかることがあります。" });
    await util.post("order/demand_debit", { plan: this.plan, rental: this.rental });
    router.push({ name: "shift", query: util.passquery["shift"] });
    dialog.openAlertDialog({
      msg: "預金口座振替依頼書をお送りいたします。",
      detail:
        "本使用のご登録ありがとうございます。<br>預金口座振替依頼書をお送りいたしますので、しばらくお待ちください。",
    });
  }

  @Action({ rawError: true })
  public execOrderCard({ company_id }: { company_id: number }) {
    dialog.openConfirmDialog({
      msg: "以上の内容で本登録の申し込みを行ってよろしいですか？",
      cancel_button: "送信しない",
      exec_button: "送信する",
      is_next_dialog: true,
      func: this.execOrderCardProcess,
      args: { company_id },
    });
  }

  @Action({ rawError: true })
  public async execOrderCardProcess({ company_id }) {
    var xml = '<?xml version="1.0" encoding="utf-8"?>';
    xml += '<request service="token" action="newcard">';
    xml += "<authentication>";
    xml += "<clientip>2011000406</clientip>";
    xml += "</authentication>";
    xml += "<card>";
    xml += "<cvv>" + this.card_cvc + "</cvv>";
    xml += "<number>" + this.card_number + "</number>";
    xml += "<expires>";
    xml +=
      "<year>" + util.formatDate(util.date2obj(this.card_yearmonth + "-01"), "yyyy") + "</year>";
    xml +=
      "<month>" + util.formatDate(util.date2obj(this.card_yearmonth + "-01"), "mm") + "</month>";
    xml += "</expires>";
    xml += "<name>" + this.card_name + "</name>";
    xml += "</card>";
    xml += "</request>";

    dialog.openProgressDialog({ title: "送信中", msg: "この処理には時間がかかることがあります。" });
    var res = await util.post("order/demand_card", { xml: xml });
    var dom = res.data;
    var oParser = new DOMParser();
    var oDom = oParser.parseFromString(dom, "text/xml");
    var status = oDom.getElementsByTagName("status")[0].innerHTML;

    if (status == "success") {
      //与信
      var token_key = oDom.getElementsByTagName("token_key")[0].innerHTML;
      var res = await util.post("order/register_credit", {
        company_id,
        token_key: token_key,
        plan: this.plan,
        rental: this.rental,
        card_yearmonth: this.card_yearmonth,
      });
      if (res.data.code == 0) {
        node.disable();
        router.push({ name: "shift", query: util.passquery["shift"] });
        dialog.openAlertDialog({
          msg: "クレジットカードの登録が完了致しました。",
          detail: "本使用のご登録ありがとうございます。",
        });
      } else {
        dialog.openErrorAlertDialog({
          msg: "クレジットカードの登録中にエラーが発生しました。",
          detail: "お手数ですが、ARROWへお問い合わせください。",
        });
      }
    } else if (status == "invalid") {
      //エラー
      var code = oDom.getElementsByTagName("code")[0].innerHTML;
      dialog.openErrorAlertDialog({ msg: this.error_msg[code] });
    } else if (status == "maintenance") {
      //メンテナンス中
      dialog.openErrorAlertDialog({ msg: this.error_msg["88888888"] });
    }
  }

  @Action({ rawError: true })
  public async stopNewsletter() {
    dialog.openProgressDialog({ title: "停止中", msg: "この処理には時間がかかることがあります。" });
    await util.post("order/stop_newsletter", {});
    header.disable_all();
    dialog.openAlertDialog({ msg: "メルマガを停止しました。" });
  }

  @Action({ rawError: true })
  public async startNewsletter() {
    dialog.openProgressDialog({ title: "再開中", msg: "この処理には時間がかかることがあります。" });
    await util.post("order/start_newsletter", {});
    header.disable_all();
    dialog.openAlertDialog({ msg: "メルマガを再開しました。" });
  }

  @Action({ rawError: true })
  public async cancelUse({ company_code }) {
    dialog.openForceConfirmDialog({
      msg: "解約を行ってよろしいですか？",
      detail:
        "解約を行うと、ARROW上のデータが全て削除され、元に戻すことはできません。<br>また、今月分までは料金が発生します。",
      cancel_button: "解約しない",
      exec_button: "解約する",
      is_next_dialog: true,
      func: this.cancelUseConfirm,
      args: { company_code },
    });
  }
  @Action({ rawError: true })
  public async cancelUseConfirm({ company_code }) {
    dialog.openForceConfirmDialog({
      msg: "本当に解約を行ってよろしいですか？",
      detail:
        "解約を行うと、ARROW上のデータが全て削除され、元に戻すことはできません。<br>また、今月分までは料金が発生します。",
      cancel_button: "解約しない",
      exec_button: "解約する",
      is_next_dialog: true,
      func: this.cancelUseProcess,
      args: { company_code },
    });
  }
  @Action({ rawError: true })
  public async cancelUseProcess({ company_code }) {
    dialog.openProgressDialog({ title: "解約中", msg: "この処理には時間がかかることがあります。" });
    await util.post("order/cancel_use", {});
    await util.post("login/logout_legacy", {}, false);
    session.deleteSession();
    before_login.disable({});
    router.push({ name: "login", params: { company_code: company_code } });
    dialog.openAlertDialog({ msg: "解約が完了しました。<br>ご利用ありがとうございました。" });
  }
}

export default getModule(Order_manager);
