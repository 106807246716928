
import { Component, Vue, Prop, Watch, Mixins } from 'vue-property-decorator';
import * as util from '@/util';
import mixinOrder from '@/mixins/mixinOrder';

@Component({
  components: {
  }
})
export default class Main extends Mixins(mixinOrder) {
  created() {
    if (this.global.plan == 2) {
      this.m.setPlan(1);
    } else {
      this.m.setPlan(2);
    }
    
  }
  get plan() {
    return this.m.plan;
  }
  set plan(val) {
    this.m.setPlan(val);
  }
}
